
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import {active} from "@/core/data/genericData";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";

export default defineComponent({
  name: "edit-categories-modal", 
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: { 
    AddTagsModal   
    },    
  setup(props) {    
    const active_list_data = active;
    // this.$forceUpdate();
    var tag_list: any[] = []
    var industry_type_list : any[] = []
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();

    let rawImg = ref() ;
    function encodeImagetoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (event) => {
        rawImg.value = {file_name:element.target.files[0].name, file_data: reader.result}
        console.log(rawImg.value)
       };
        
      }

    const UploadImage = async (category_id) => {
      const db_data = {
        "category_id" : category_id,
        "file_name" : rawImg.value.file_name,
        "file_data" : rawImg.value.file_data,
        "user_id" : 1,
        }
       
          await store.dispatch(Actions.CUST_ADD_CAT_IMAGE, db_data).then(({ data }) => {
           if (data){
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editCategoryModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }


    const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "category_id": data.ids, "search_term" : "", "page": 1, "records_per_page": 1 }
         await store.dispatch(Actions.CUST_GET_CATEGORY_LIST, values).then(({ data }) => {       
          
          for (var i = 0; i < data.tags.length; i++){
              var obj = data.tags[i];
              tag_list.push(obj.tag_name)
          }

          for (var j = 0; j < data.industry_types.length; j++){
              var industry_obj = data.industry_types[j];
              industry_type_list.push(industry_obj.company_industry_type_id)
          }


          formData.value = {
            id : data.category_id ,
            name: data.category_name,
            description:  data.category_description,
            parent_select: data.category_id_parent,    
            shape_select: data.shape_id,    
            form_select: data.category_form_id,
            stage_select : data.category_stage_id,
            length_select: data.category_length_type_id,
            industry_select: industry_type_list,
            tag_select: tag_list,
            hsn_select : data.hsn,
            active: data.active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

     const shape = ref([]);
     const setShapedata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_GET_SHAPE_CATEGORY, db_data).then(({ body }) => {
          shape.value = body.product;
          //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const industry = ref([]);
    const setIndustrydata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_INDUSTRY_CATEGORY, param).then(({ body }) => {
            industry.value = body.industry_types_pc;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const form = ref([]);
    const setFormdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_FORM_CATEGORY, param).then(({ body }) => {
            form.value = body.form;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const stage = ref([]);
    const setStagedata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_Stage_CATEGORY, param).then(({ body }) => {
            stage.value = body.stage;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const length = ref([]);
    const setLengthdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_Length_CATEGORY, param).then(({ body }) => {
            length.value = body.length;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parent = ref([]);
    const setParentData = async (data) => {
        loading.value = true;
        try {
          var values = {"search_term" : data}
          if(values.search_term){
            console.log("values")
            console.log(values)
            await store.dispatch(Actions.CUST_GET_CATEGORY, values).then(({ data }) => {
            parent.value = data.page_data;
            loading.value = false;
           
        })
        .catch(({ response }) => {
          console.log(response);        
        });
       }  
      } catch (e) {
        console.log(e);
      }
       
    }

 
    const tag = ref([]);
    const setTagData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term" : data}
          if(values.search_term){
          await store.dispatch(Actions.CUST_GET_TAGLIST, values).then(({ data }) => {
          tag.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
          }
      } catch (e) {
        console.log(e);
      }

    }
    

    onMounted( async () => {      
      await setFormData(props.data);
      await setShapedata(shape.value)
      //await setTagData("")
      await setIndustrydata(industry.value)
       await setFormdata(form.value)
      await setStagedata(stage.value)
      await setLengthdata(length.value)
      //await setParentData("")
      //await setHsnData("")
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editCategoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    
    //const loadingHsn = ref<boolean>(false);

 const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
      parent_select: [
        {
          required: true,
          message: "Parent Category is required",
          trigger: "change",  
        }
      ],
      shape_select: [
        {
          required: true,
          message: "Shape is required",
          trigger: "change",  
        }
      ],
      form_select: [
        {
          required: true,
          message: "Category Form is required",
          trigger: "change",  
        }
      ],
      stage_select: [
        {
          required: true,
          message: "Stage is required",
          trigger: "change",  
        }
      ],
      length_select: [
        {
          required: true,
          message: "Length is required",
          trigger: "change",  
        }
      ],
      industry_select: [
        {
          required: true,
          message: "Industry type is required",
          trigger: "click",  
        }
      ],
       tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",  
        }
      ],
      hsn_select: [
        {
          required: true,
          message: "Hsn is required",
          trigger: "change",  
        }
      ],
    });

  const hsn = ref([]);
     const setHsnData = async (data) => {
      const db_data = {
        search_term: ""
      }
        loading.value = true;
        try {
          var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_HSN_CATEGORY, values).then(({ data }) => {
          hsn.value = data;
          console.log("HSN VALUE")
          console.log(hsn.value)
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const addtag : any[] = [];
    const setCategoryData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());     
       var tag_id_list : any [] = []
      var tag_text_list : any [] = []
      for (let j = 0; j < data.tag_select.length; j++){
        console.log(data.tag_select[j]);
        console.log(typeof(data.tag_select[j]))
        console.log(typeof(data.tag_select[j]) == 'string')
        if (typeof(data.tag_select[j]) == 'string')
        {
          console.log("11111111111111111")
          
          tag_text_list.push(data.tag_select[j])
          console.log(tag_text_list)
        }
        else if(typeof(data.tag_select[j]) == 'number')
        {
          tag_id_list.push(data.tag_select[j])
        }

      }
     
      for (let i=0; i<tag_text_list.length; i++)
      {
        const db_data = {
          "tag_name": tag_text_list[i],
          "created_user_id": "1",
          }
           await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
           
           addtag.push(data.tag_id);
           console.log("mmmmmmmmmmm")
           console.log(addtag)
          })
      }
      
      const merge = tag_id_list.concat(addtag);

      let parentcat = 0
      if (!data.parent_select)
      {
        parentcat = 0
         
      }
      else{
        parentcat = data.parent_select
      }
     
      const db_data = {
        "category_id": data.id,
        "category_name": data.name,
        "category_description": data.description,
        "parent_id": parentcat,        
        "shape_id":data.shape_select,
        "category_form": data.form_select,
        "category_stage": data.stage_select,
        "category_length_type": data.length_select,
        "category_industry_types": data.industry_select,
        "tag_ids": merge,
        "hsn_code": data.hsn_select,
        "active": data.active,
        }

        await store.dispatch(Actions.CUST_UPDATE_CATEGORY, db_data).then(({ data }) => {
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Category has been successfully edited.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editCategoryModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setCategoryData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setFormdata,
      setLengthdata,
      setStagedata,
      setShapedata,
      setIndustrydata,
      setParentData,
      setTagData,
      setHsnData,
      form,
      length,
      stage,
      hsn,
      shape,
      tag,
      industry,
      parent,
      editCategoryModalRef,
      tag_list,
      industry_type_list,
      active_list_data
    };
  },
});
